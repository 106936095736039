import React from "react";
import "./index.less";
// const WosiFriendsBg = require('../../../public/wosiImage/life-bg.png')
import WosiFriendsBg_1 from "../../../../../public/wosiImage/friends-bg.png";
import DownArrow from "../../../public/wosiImage/down.png";

const WosiFriends = () => {
  return <>
    <div className="WosiFriends">
      <div className="WosiFriends-slogan">
        <img className="slogan-img-1" src={WosiFriendsBg_1} alt="" />
        <div className="slogan-title">
          <p>我思故我在</p>
        </div>
      </div>

      <footer className="App-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        {" "}
        蜀ICP备17038486号-2{" "}
      </a>
      <div style={{ margin: "0 auto;" }}>
        <a
          target="_blank"
          href=" "
          style={{
            display: "block",
            textDecoration: "none",
            height: "20px",
            lineHeight: "20px",
            marginRight: '10px'
          }}
        >
          <img src="https://bkssl.bdimg.com/static/wiki-common/widget/component/footer/img/icon-police_5f07082.png" style={{ float: "left", marginLeft: '10px' }} />
          <p
            style={{
              float: "left",
              height: "20px",
              lineHeight: "20px",
              margin: "0px 0px 0px 5px",
              color: "#939393",
            }}
          >
            川公网安备 51078102110219号
          </p>
        </a>
      </div>
    </footer>
    </div>
  </>;
};

export default WosiFriends;
