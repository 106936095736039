import React, { useState } from 'react';
import { useEffect } from 'react'
import '../../App.less';
import NavBar from '../../components/nav'
import Wosi from './components/wosi';
import fullpage from '../../utils/fullpage/fullpage.js'
import WosiLife from './components/life';
import WosiAi from './components/ai';
import WosiFriends from './components/friends';
import WosiCloud from './components/cloud';

let homeFullpageInited = false

function HomePage() {
  const initFullPage = () => {
    homeFullpageInited = true
    // @ts-ignore
    if (window && window.fullpage_api && window.fullpage_api.destroy && typeof window.fullpage_api.destroy === 'function') {
      // @ts-ignore
      window.fullpage_api.destroy('all')
    }
    setTimeout(() => {
      // @ts-ignore
      new fullpage('#homeFullPage', {
        //options here
        autoScrolling:true,
        scrollHorizontally: true,
        navigation: true,
        active: 1
      })

      // @ts-ignore
      window.fullpage_api.moveTo(1)
    }, 200);
  }

  useEffect(() => {
    homeFullpageInited = false
    const docEl = document.documentElement
      const resizeEvt = 'orientationchange' in window ? 'orientationchange': 'resize'
      const recalc = () => {
        const clientWidth = docEl.clientWidth
        if (!clientWidth) return

        if (clientWidth > 1000) {
          docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
        } else {
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
        }
      };

      recalc()

      if (!homeFullpageInited) {
        initFullPage()
      }
      if (window.addEventListener || document.addEventListener) {
        window.addEventListener(resizeEvt, recalc, false)
        document.addEventListener('DOMContentLoaded', recalc, false)
      }

      window.addEventListener("hashchange", () => {
        //  清理之前的监听
          if (window.removeEventListener || document.removeEventListener) {
          window.removeEventListener(resizeEvt, recalc, false)
          document.removeEventListener('DOMContentLoaded', recalc, false)
        }
      });
        
        
    return () => {
      // if (homeFullpageInited) {
      //   console.log('执行 return ')
      //   // 切换页面前 - 销毁 fullpage - fullpage全局只能保留一个
      //   // @ts-ignore
      //   if (window && window.fullpage_api && window.fullpage_api.destroy && typeof window.fullpage_api.destroy === 'function') {
      //     console.log(' leave friends ....')
      //     // @ts-ignore
      //     window.fullpage_api.destroy('all')
      //   }
      // }
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <div className='Content'>
        <div className='fullPage' id='homeFullPage'>
          {/* 我思 */}
          <div className='section'><Wosi /></div>
          {/* 重新解构人类需求 */}
          <div className='section'><WosiCloud /></div>
          {/* 自建生态人工智能 */}
          <div className='section'><WosiAi /></div>
          {/* 创造未来生活方式 */}
          <div className='section'><WosiLife /></div>
          {/* 我思故我在 */}
          <div className='section'><WosiFriends /></div>
        </div>
      </div>
    {/* <footer className="App-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank"> 蜀ICP备17038486号-2 </a> 
      <div style={{margin:'0 auto; padding:20px 0'}}>
        <a target="_blank" href=" "
          style={{
            display: 'inline-block',
            textDecoration: 'none',
            height: '20px',
            lineHeight: '20px',
          }}>
            < img src="" style={{ float: 'left' }}/>
            <p style={{
              float: 'left',
              height: '20px',

              lineHeight:'20px',
              margin: '0px 0px 0px 5px',
               color:'#939393'
              }}>川公网安备 51078102110219号</p ></a >
    </div>
    </footer> */}
    </div>
    
  );
}

export default HomePage;
